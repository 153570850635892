import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-individual-gbs',
  templateUrl: './individual-gbs.component.html',
  styleUrls: ['./individual-gbs.component.scss']
})
export class IndividualGbsComponent implements OnInit {
  customerTestArray: any = [];
  card_details: any = [];

  constructor(private router: Router, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private dom) {
    this.metaService.updateTag({ name: 'description', content: "Ensure a healthy pregnancy with Group B Strep testing at Concepto Diagnostics UK. Comprehensive screening for Group B Streptococcus to safeguard maternal and fetal well-being. " });
    this.metaService.updateTag({ name: 'keywords', content: 'Group B Strep Test UK, Group B Strep Test UK, Genetics Testing UK' });
    this.titleService.setTitle('Group B Strep Testing During Pregnancy | Concepto Diagnostics UK');
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', 'https://conceptodiagnostics.co.uk/group-b-strep')
  }

  ngOnInit(): void {
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Concepto-GBS"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.customerTestArray = [
      {
        link: "GBS-home-Test",
        images: "../../assets/images/gbs/gbs.png",
        title: "GBS Home Test kit",
        description: ['The GBS test kit uses the ECM test to determine GBS carriage at delivery. GBS testing is recommended during pregnancy for those who are due to deliver within the next 5 weeks.', 'Order this test if you have a gestation of 35 or >35 weeks. ', 'When you order this test:'],
        list: [
          "A kit is dispatched to your address",
          "Receive the kit, read the instructions sent",
          "Follow the instruction, collect your swab",
          "Seal the prepaid envelope and send back ",
          "Note the tracking number to track delivery"
        ],
        description_two: 'Receive test result on email within 48 hours, once the sample reaches our laboratory.',
        description_three: 'Orders received after 3:30 PM on weekdays will be shipped on the following working day. All orders placed during the weekend will be shipped on the next working day.',

        list_one: [
        ],
        price: "25",
        button: "ORDER NOW",
        booking_id: "https://buy.stripe.com/28o6rJ1Hg9om3HqbIP",
        type: 'stripe'
      }
    ]
    let getUrl = this.router.url.split('=')[1]
    this.card_details = this.customerTestArray.filter(item => item.link === getUrl)[0];
  }

  booknow() {
    let p = this.card_details;
    if (p.type === 'concepto') {
      window.open(`${AppConstants.appointmentForms}location?appointmentTypeID=${p.booking_id}`)
    }else if(p.type === 'stripe'){
      window.open(`${p.booking_id}`)
    }
  }
}



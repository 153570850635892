import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GeneralService } from './general.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'landing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('scroll', [
      state('on', style({right: '100%'})),
      transition('* => *', [
        style({right: '-1150px'}),
        animate(40000, style({right: '100%'}))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'landing';
  showBookNow = false;
  showAesthetics: boolean = false;
  state = 0;
  constructor(private router: Router, private generalService: GeneralService) {
    if (this.generalService.getCookie('coc_user') && this.generalService.getCookie('coc_token')) {
    } else {
      this.generalService.clearCookie()
    }
    if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
      this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')), 2)
    } else {
      this.generalService.setCookie('coc_cart_details', 0, 2)
    }

  }

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      if (this.router.url.toString().includes('/aesthetics')) {
        this.showAesthetics = true;
      } else {
        this.showAesthetics = false;
      }
    })
  }

  scrollDone() {
    this.state++;
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-test-codes',
  templateUrl: './test-codes.component.html',
  styleUrls: ['./test-codes.component.scss']
})
export class TestCodesComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private dom) { 
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', 'https://conceptodiagnostics.co.uk/testcodes')
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }

  }

}

import { DOCUMENT } from '@angular/common';
import { GeneralService } from './../general.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {
  news_paper: any = [];
  news_oct: any = [];
  news_sep: any = [];
  news_aug: any = [];
  news_jul: any=[];
  news_jun: any=[];
  news_may: any=[];
  news_apr: any=[];
  news_mar: any=[];
  news_feb: any=[];
  news_jan: any=[];
  
  constructor(@Inject(DOCUMENT) private dom) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', 'https://conceptodiagnostics.co.uk/press')
   }

  ngOnInit(): void {
     this.news_paper = [
      {
        date: '13th Jun 2022',
        heading: `Long Covid symptoms: How to manage 'distressing' change in hearing - expert`,
        description: `Those suffering the long-term effects of COVID-19 have to deal with several debilitating issues. Many with long Covid are 
        living with fatigue as well as lingering breathing problems. However, some are dealing with more "unusual" symptoms.`,
        link: 'https://www.express.co.uk/life-style/health/1624915/long-covid-how-to-manage-tinnitus-symptoms'
      },
      {
        date: '13th Jun 2022',
        heading: `Long Covid symptoms: How to manage 'distressing' change in hearing - expert`,
        description: `A recent study by King's College London revealed that one in five people who tested positive for a Covid experienced tinnitus at the same time. Tinnitus is a ringing, buzzing, whining or other noises in one or both of your ears or your head that isn't from an external source. It has since been listed by the National Institute for Health and Care Excellence as a symptom of long Covid.`,
        link: 'https://www.msn.com/en-gb/health/mindandbody/long-covid-symptoms-how-to-manage-distressing-change-in-hearing-expert/ar-AAYpAkE'
      },
      {
        date: '14th Jun 2022',
        heading: `Unusual chickenpox symptoms to watch for - feeling irritable and having cold feet`,
        description: `Chickenpox is common and mostly affects children, but you can get it at any age. Feeling irritable is a lesser-known early sign of the condition.`,
        link: 'https://www.mirror.co.uk/lifestyle/health/unusual-chickenpox-symptoms-watch-feeling-27222122'
      },
      {
        date: '16th Jun 2022',
        heading: `Health experts share why hay fever is worse in cities as Edinburgh hit with 'pollen-bomb'`,
        description: `As we welcome summer weather, many will be tempted to stay indoors as those who suffer from hay fever have been feeling the impact. But even in a city, away from countr side, health experts have explained why we are still feeling bunged up.`,
        link: 'https://www.edinburghlive.co.uk/news/edinburgh-news/health-experts-share-hay-fever-24245019'
      },
      {
        date: '17th Jun 2022',
        heading: `Doctor Shares Warning Over 'Extremely Concerning' Nasal Tanning Sprays`,
        description: `A doctor has warned of the dangers of nasal tanning sprays which have gone viral on social media in recent weeks. By now, most people are aware of the dangers of sunbeds and tanning outdoors without using adequate sun protection cream. sunbeds are a serious cause for concern because they give out ultraviolet (UV) rays, which can increase your risk of developing skin cancer.This led to the introduction of the Sunbeds (Regulation) Act 2010 which came into force on 8 April 2011 to ensure that no person under the age of 18 years uses a sun bed.`,
        link: 'https://www.tyla.com/health/nasal-tanning-spray-melanotan-side-effects-dangers-illegal-uk-20220615'
      },
      {
        date: '22th Jun 2022',
        heading: `What are the signs of prostate cancer?`,
        description: `In the UK, prostate cancer is the most common cancer in men and is responsible for 12,000 deaths a year. Depending on how the cancer cells grow, you may or may not develop noticeable signs of prostate cancer. To give yourself the best chance of diagnosis and survival, it's important to be aware of the key signs, and also to attend regular GP check-ups once you hit your forties.`,
        link: 'https://patient.info/news-and-features/what-are-the-signs-of-prostate-cancer'
      },

    ]
    this.datasort()

    
    
  }
  datasort(){
    this.news_paper.forEach(month=>{
      let splitdata=month.date.split(" ")[1]     
      
      if(splitdata==='Oct'){
        this.news_oct.push(month)
      }

      if(splitdata==='Sep'){
        this.news_sep.push(month)
      }

      if(splitdata==='Aug'){
        this.news_aug.push(month)
      }

      if(splitdata==='Jul'){
        this.news_jul.push(month)
      }

      if(splitdata==='Jun'){
        this.news_jun.push(month)
      }
      
      if(splitdata==='May'){
        this.news_may.push(month)
      }
      if(splitdata==='Apr'){
        this.news_apr.push(month)
      }
      if(splitdata==='Mar'){
        this.news_mar.push(month)
      }
      if(splitdata==='Feb'){
        this.news_feb.push(month)
      }
      if(splitdata==='January'){
        this.news_jan.push(month)
      }

    })
    

    }
  express(link) {
    window.open(link);
  }


}

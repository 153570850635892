import { Component, OnInit, Inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GeneralService } from '../general.service';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-mobile-phlebotomist',
  templateUrl: './mobile-phlebotomist.component.html',
  styleUrls: ['./mobile-phlebotomist.component.scss'],
  providers: [MessageService]
})
export class MobilePhlebotomistComponent implements OnInit {
  
  questions_form:boolean = false
  selectedForm: any
  acceptedFiles = '.pdf, .docx'
  fileUpload: any;
  uploadedFiles: any[] = [];
  base64ImageValue: any
  loading:boolean = false
  documentLink: any = ""
  submitted:boolean = false
  filepicked:boolean = false
  emailNotValid:boolean = false
  phoneNotValid:boolean = false
  formSubmitted:boolean = false
  // showTrustPilot = false;
  constructor(private generalService: GeneralService, private messageService: MessageService,@Inject(DOCUMENT) private dom) { 
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', 'https://conceptodiagnostics.co.uk/mobile-phlebotomist')
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.showTrustPilot = true;
    // }, 1000);
  }

  getQuestions(){
    this.loading = true
    this.generalService.getQuestions("2").subscribe(res=>{
      this.selectedForm = JSON.parse(res[0].form)
      this.questions_form = true
      this.submitted = false
      this.loading = false
    })
  }

  async save(){
    this.formSubmitted = true
    const regexForPhone = new RegExp("^[0-9]+$");
    const regexForEmail = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    let statusArr = []
    await this.selectedForm.forEach((element)=>{
      let value
      if(typeof element.value === 'string'){
        value = element.value.trim();
        if(element.required && (value.length === 0 || element.value === null) && element.type != 'upload'){
          statusArr.push(true)
        }
        if(element.required && element.name.toLowerCase().includes('email') ){
          if(!regexForEmail.test(element.value)){
            statusArr.push(true)
            this.emailNotValid = true
          } else {
            this.emailNotValid = false
          }
        }
        if(element.required && element.name.toLowerCase().includes('phone') ){
          if(!regexForPhone.test(element.value) || element.value.length < 10 || element.value.length > 14){
            statusArr.push(true)
            this.phoneNotValid = true
          } else {
            this.phoneNotValid = false
          }
        }
        if(element.type == 'upload'){
          element.value = this.documentLink
          if(element.required && this.documentLink == ""){
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload the file.' });
          }
        }
      }      
    })
    if (statusArr.includes(true)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill all required fields.' });
    }
    if (statusArr.includes(true) || this.documentLink == "") {
      
    } else {
      this.loading = true
      let body = {
        id: 0,
        name: "",
        email_address: "",
        phone_number: "",
        form_type: "Mobile Phlebotomist",
        form_fields: "",
        submitted_docs: this.documentLink,
        submitted_date: moment().format('DD/MM/YYYY HH:mm')
      }
      await this.selectedForm.forEach(element => {
        if(element.name == "Name"){
          body.name = element.value
        }
        if(element.name == "Email address"){
          body.email_address = element.value
        }
        if(element.name == "Phone number"){
          body.phone_number = element.value
        }
      });
      body.form_fields = JSON.stringify(this.selectedForm)
      this.generalService.createOnboarding(body).subscribe(res=>{
        this.questions_form = false
        this.submitted = true
        this.fileUpload?.clear()
        this.documentLink = ""
        this.uploadedFiles = []
        this.loading = false
        this.formSubmitted = false
        this.filepicked = false
      })
    }
  }

  onfileUpload(event: any, fileUpload: any, field) {
    this.fileUpload = fileUpload
    this.loading = true
    this.uploadedFiles = []
    let size = 0
    event.files?.forEach((file: any, i: any) => {
      size = size + file.size
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let full_name = file.name.split('.')
        let length = full_name.length
        let file_type = full_name[length - 1]
        let temp = full_name.splice(0, length - 1)
        let obj = {
          filename: Math.floor(100000 + Math.random() * 900000)+'-'+temp.join(' '),
          type: file_type,
          file: reader.result
        }
        this.uploadedFiles.push(obj);
      };
      if (event.files.length - 1 === i) {
        let timeout = 3000
        if (size > 700000) {
          timeout = 5000
        }
        if (size > 1000000) {
          timeout = 7000
        }
        setTimeout(() => {
          let temp: any = []
          this.base64ImageValue = this.uploadedFiles
          this.base64ImageValue.forEach((el: any, i: any) => {
            el.file = el.file.split(',')[1]
            temp.push(el)
          })
          this.base64ImageValue = JSON.stringify(temp)
          let body = {
            "image": this.base64ImageValue
          }
          this.generalService.uploadDocument(body).subscribe(res=>{
            this.documentLink = JSON.parse(res['image']).image[0]
            this.loading = false
            this.filepicked = false
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your file has been uploaded successfully.' });
          })
        }, timeout);
      }
    });
  }

  onRemoveFile(event){
    this.uploadedFiles = []
    this.fileUpload?.clear()
    this.documentLink = ""
    this.filepicked = false
  }

  Cancel(){
    this.questions_form = false
    this.submitted = false
    this.fileUpload?.clear()
    this.documentLink = ""
    this.uploadedFiles = []
    this.filepicked = false
    this.formSubmitted = false
  }

  async validationCheck(){
    const regexForPhone = new RegExp("^[0-9]+$");
    const regexForEmail = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    await this.selectedForm.forEach((element)=>{
      let value
      if(typeof element.value === 'string'){
        value = element.value.trim()
        if(element.required && element.name.toLowerCase().includes('email') ){
          if(!regexForEmail.test(element.value)){
            this.emailNotValid = true
          } else {
            this.emailNotValid = false
          }
        }
        if(element.required && element.name.toLowerCase().includes('phone') ){
          if(!regexForPhone.test(element.value) || element.value.length < 10 || element.value.length > 14){
            this.phoneNotValid = true
          } else {
            this.phoneNotValid = false
          }
        }
      }      
    })
  }
}

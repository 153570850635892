import { DOCUMENT } from '@angular/common';
import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gbs',
  templateUrl: './gbs.component.html',
  styleUrls: ['./gbs.component.scss']
})
export class GbsComponent implements OnInit {
  section_two_data: any = [];
  section_three_data: any = [];
  section_four_data: any = [];

  public sizedesk: boolean;
  // public sizemob = false;

  constructor(private router: Router, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private dom) {
    this.metaService.updateTag({ name: 'description', content: "Ensure a healthy pregnancy with Group B Strep testing at Concepto Diagnostics UK. Comprehensive screening for Group B Streptococcus to safeguard maternal and fetal well-being. " });
    this.metaService.updateTag({ name: 'keywords', content: 'Group B Strep Test UK, Group B Strep Test UK, Genetics Testing UK' });
    this.titleService.setTitle('Group B Strep Testing During Pregnancy | Concepto Diagnostics UK');
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href', 'https://conceptodiagnostics.co.uk/group-b-strep')
    this.section_two_data = [{
      "header": "What is GBS?",
      "description": "Group B Streptococcus (Group B Strep, Strep B, Beta Strep, or GBS) is a type of bacteria which lives in the intestines, rectum and vagina of around 20-40% women in the UK.",
    },
    {
      "header": "What are its symptoms?",
      "description": "GBS is one of the many bacteria that normally live in our bodies and which usually cause no harm. A pregnant person with GBS can pass the bacteria to their baby during vaginal delivery. Infants, older adults or people with a weakened or underdeveloped immune system are more likely to develop complications from GBS. It can cause severe and even life-threatening infections such as sepsis, pneumonia or meningitis in a small percentage of new-borns."

    }

    ];

    this.section_three_data = [{
      "header": "How is it transmitted?",
      "description": "Group B Strep is not a sexually transmitted disease. Most women carrying GBS will have no symptoms. Carrying GBS is not harmful to you, but it can affect your baby around the time of birth.",
    },
    {
      "header": "When should you get tested?",
      "description": "If you are pregnant, the ideal time to get tested is within 5 weeks of giving birth, i.e., at 35-37 weeks of pregnancy."

    }

    ];

    this.section_four_data = [
      {
        "header": "How does the test work?",
        "description": "The Enriched Culture Medium (ECM) test is the Gold Standard test used to determine GBS carriage. For this test, you need to collect a swab of the lower vagina and rectum using the swabs given as part of the kit and then sending it to our lab for analysis. These two swabs need to be cultured, to predict GBS carriage around the time of delivery.",
      },
      {
        "header": "How accurate is this test?",
        "description": "According to research, an ECM test, when performed within 5 weeks of delivery, has the below mentioned accuracy:",
        "points": [
          { "point": "A negative result was 96% predictive that a woman would not be carrying group B Strep at delivery (4% of women acquired GBS carriage between the test and giving birth)." },
          { "point": "A positive result was 87% predictive that a woman would be carrying group B Strep at delivery (13% of women lost carriage between doing the test and giving birth)." }
        ]

      },
      {
        "header": "What are the treatment guidelines for GBS?",
        "description": "As per established guidelines in the UK. if GBS is detected during your current pregnancy, you should be offered intravenous antibiotics during labour. This will stop most GBS infections from developing in new born babies."

      }
    ];

  }

  ngOnInit(): void {
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        console.log(menuitem[i]["outerText"])
        if(menuitem[i]["outerText"] === "Concepto-GBS"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.onResize()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 992) {
      this.sizedesk = true
    } else {
      this.sizedesk = false
    }
  }

  orderNow() {
    let link = 'GBS-home-Test'
    this.router.navigate(['/group-b-strep/individual'], { queryParams: { service: link } });

  }
}

import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  items: any = [];
  visibleSidebar = false;
  activeClick: any;
  pageYoffset = 0;
  addedToCart: any;
  userLoggedIn = true;
  appointmentName = 'Concepto';
  displayCartLogo = true
  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  constructor(private router: Router, private scroll: ViewportScroller, private generalService: GeneralService) {
    this.router.events.subscribe(value => {
      if (this.router.url.toString() === '/nipt') {
        this.appointmentName = 'NIPT';
      } else if (this.router.url.toString().includes('/nipt')) {
        this.appointmentName = 'NIPT';
      } else if (this.router.url.toString().includes('/aesthetics')) {
        this.appointmentName = 'Aesthetics';
      } else {
        this.appointmentName = 'Concepto';
      }
    });
    this.addedToCart = this.generalService.getCookie('coc_active_cart')
    if (this.addedToCart === null || this.addedToCart === undefined) {
      this.addedToCart = false;
    }

    let existing_user = this.generalService.getCookie('coc_user')
    if (existing_user) {
      this.userLoggedIn = true
      this.addLogout()
    } else {
      this.userLoggedIn = false
      this.removeLogout()
    }

    this.generalService.listenCookieChange(({ oldValue, newValue }) => {
      if (newValue && newValue.length > 0) {
        let value = newValue.split(';')
        if (value && value.length > 0) {
          value.forEach(element => {
            if (element.includes('coc_active_cart')) {
              let cartstatus = element.split('=')[1];
              if (cartstatus === 'true') {
                this.addedToCart = true
              } else {
                this.addedToCart = false
              }
            }
          });
        }
      }


    }, 1000);
  }

  ngOnInit(): void {
  }

  profile() {
    let existing_user = this.generalService.getCookie('coc_user')
    if (existing_user) {
      this.router.navigateByUrl(`profile`);
      this.visibleSidebar = false;
      this.userLoggedIn = true
      this.addLogout()
    } else {
      this.router.navigateByUrl(`login`);
      this.visibleSidebar = false;
      this.userLoggedIn = false
      this.removeLogout()
    }
  }

  diagnostics() {
    this.visibleSidebar = false;
    this.router.navigate(['/diagnostics'])

  }

  hometestKits() {
    this.router.navigateByUrl(`diagnostics/antigen-home-kits`);
    this.visibleSidebar = false;
  }

  cart() {
    this.router.navigateByUrl(`cart`);
    this.visibleSidebar = false;
    let existing_user = this.generalService.getCookie('coc_user')
    if (existing_user) {
      this.userLoggedIn = true
      this.addLogout()
    } else {
      this.userLoggedIn = false
      this.removeLogout()
    }
  }

  logout() {
    this.router.navigateByUrl(`login`);
    this.visibleSidebar = false;
    this.userLoggedIn = false
    this.generalService.clearCookie();
    this.removeLogout()
  }

  chinaTests() {
    this.router.navigateByUrl(`diagnostics/covid-test-filtered?type=china-travel-test`);
    this.visibleSidebar = false;
  }

  downloadReport() {
    this.router.navigateByUrl('profile')
    // window.open(`${AppConstants.API_ENDPOINT}customer-login`, '_blank');
    this.visibleSidebar = false;
  }

  postalKit() {
    window.open('http://verification.conceptoclinicapp.co.uk/', '_blank');
    this.visibleSidebar = false;
  }

  corporateTests() {
    this.router.navigateByUrl('diagnostics/corporate-testing')
    this.visibleSidebar = false;
  }
  faqs() {
    this.router.navigateByUrl(`frequently-asked-questions`);
    this.visibleSidebar = false;
  }

  contactUs() {
    this.router.navigateByUrl(`contact-us`);
    this.visibleSidebar = false;
  }

  wellness() {
    this.router.navigateByUrl(`wellness`);
    this.visibleSidebar = false;
  }

  // aesthetics() {
  //   this.router.navigateByUrl(`aesthetics`);
  //   this.visibleSidebar = false;
  // }
  // imaging() {
  //   document.location.href = `${AppConstants.BASE_URL_IMAGING}`
  //   // this.router.navigate(['/imaging']);
  //   this.visibleSidebar = false;
  // }


  landing() {
    this.router.navigate(['']);
    this.visibleSidebar = false;
  }
  aboutus() {
    this.router.navigate(['/aboutus'])
    this.visibleSidebar = false;
  }
  covidTests() {
    this.router.navigateByUrl(`diagnostics/covid-test`);
    this.visibleSidebar = false;
  }

  blog() {
    this.router.navigate(['/blog']);
    this.visibleSidebar = false;
  }

  nipt() {
    this.router.navigate(['/nipt']);
    this.visibleSidebar = false;
  }

  gbs() {
    this.router.navigate(['/group-b-strep']);
    this.visibleSidebar = false;
  }

  aesthetics() {
    window.open(
      'https://concepto-aesthetics.mytreatwell.co.uk/',
      '_blank' // <- This is what makes it open in a new window.
    );
    this.visibleSidebar = false;
  }

  menuClick(input) {
    if (input === 'BlueorPink') {
      window.open(
        'https://blueorpink.co.uk/product/early-gender-test?utm_source=concepto&utm_medium=referal',
        '_blank' // <- This is what makes it open in a new window.
      );
      // this.router.navigate(['/blue-pink']);
      this.visibleSidebar = false;
    } else if (input === 'NIPT') {
      this.router.navigate(['/nipt']);
      this.visibleSidebar = false;
    } else if (input === 'GBS') {
      this.router.navigate(['/group-b-strep']);
      this.visibleSidebar = false;
    } else if(input === 'trackSample'){
      window.open(
        `${AppConstants.partnerportal}/track-results`,
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }

  findClinic() {
    this.router.navigateByUrl(`clinic-finder`);
    this.visibleSidebar = false;
  }

  sti() {
    this.router.navigateByUrl(`diagnostics/sti`);
    this.visibleSidebar = false;
  }

  visibility() {
    this.visibleSidebar = true;
  }
  closeSidebar() {
    this.visibleSidebar = false
  }

  activeMenu(event) {
    // let node;
    // if (event.target.classList.contains("p-submenu-header") == true) {
    //   node = "submenu";
    // } else if (event.target.tagName === "SPAN") {
    //   node = event.target.parentNode.parentNode;
    // } else {
    //   node = event.target.parentNode;
    // }
    // if (node != "submenu") {
    //   let menuitem = document.getElementsByClassName("p-menuitem");
    //   for (let i = 0; i < menuitem.length; i++) {
    //     menuitem[i].classList.remove("active");
    //   }
    //   node.classList.add("active");
    // }
  }

  activeclick(input) {
    this.activeClick = input;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  // nutrition() {
  //   document.location.href = `${AppConstants.BASE_URL_NUTRITION}`
  //   this.visibleSidebar = false;
  // }

  promotionsNavs(input) {
    if (input === 'covid-or-flu') {
      this.router.navigateByUrl(`diagnostics/covid-or-flu`);
    }
  }

  removeLogout() {
    this.items = [
      // {
      //   label: 'Genomics',
      //   items: [
      //     { label: 'Concepto-NIPT', command: () => this.menuClick('NIPT') },
      //     { label: 'BlueorPink - Early Gender Test', command: () => this.menuClick('BlueorPink') },

      //   ]
      // },

      { label: 'Concepto-NIPT', command: () => this.menuClick('NIPT') },
      // {
      //   label: 'Aesthetics', command: () => this.aesthetics()
      // },
      { label: 'BlueorPink - Early Gender Test', command: () => this.menuClick('BlueorPink') },
      { label: 'Track your Sample', command: () => this.menuClick('trackSample') },

      // { label: 'Concepto-GBS', command: () => this.menuClick('GBS') },
      // {
      //   label: 'Covid Tests',
      //   items: [
      //     { label: 'Covid Tests', command: () => this.covidTests() },
      //     { label: 'China Travel Tests', command: () => this.chinaTests() },
      //     // { label: 'Covid or Flu', command: () => this.promotionsNavs('covid-or-flu') },

      //   ]
      // },
      // { label: 'Pathology Tests', command: () => this.bloodTests() },
      // {
      //   label: 'Diagnostics',
      //   items: [
      //     { label: 'Diagnostics Home', command: () => this.diagnostics() },
      //     // { label: 'Concepto-GBS', command: () => this.menuClick('GBS') },
      //     // { label: 'STI', command: () => this.sti() },
      //     { label: 'Pathology Tests', command: () => this.bloodTests() }

      //   ]
      // },
      // { label: 'Wellness', command: () => this.wellness() },
      // { label: 'Nutrition', command: () => this.nutrition() },
      // { label: 'Aesthetics', command: () => this.aesthetics() },
      // { label: 'NIPT', command: () => this.nipt() },
      {
        label: 'More',
        items: [
          // { label: 'Profile', command: () => this.profile() },
          { label: 'Blog', command: () => this.blogs() },
          // { label: 'Ultrasound', command: () => this.imaging() },
          // { label: 'Order Home Test Kits', command: () => this.hometestKits() },
          // { label: 'Activate Postal kit', command: () => this.postalKit() },
          // { label: 'Corporate Testing', command: () => this.corporateTests() },
          { label: 'FAQs', command: () => this.faqs() },
          { label: 'About Us', command: () => this.aboutus() },
          { label: 'Contact Us', command: () => this.contactUs() },
        ]
      }
    ]
  }

  addLogout() {
    this.items = [
      // {
      //   label: 'Genomics',
      //   items: [
      //     { label: 'Concepto-NIPT', command: () => this.menuClick('NIPT') },
      //     { label: 'BlueorPink - Early Gender Test', command: () => this.menuClick('BlueorPink') },
      //   ]
      // },

      { label: 'Concepto-NIPT', command: () => this.menuClick('NIPT') },
      { label: 'BlueorPink - Early Gender Test', command: () => this.menuClick('BlueorPink') },
      { label: 'Track your Sample', command: () => this.menuClick('trackSample') },


      // {
      //   label: 'Aesthetics', command: () => this.aesthetics(),
      // },
      // { label: 'Concepto-GBS', command: () => this.menuClick('GBS') },
      // { label: 'Pathology Tests', command: () => this.bloodTests() },
      // {
      //   label: 'Covid Tests',
      //   items: [
      //     { label: 'Covid Tests', command: () => this.covidTests() },
      //     { label: 'China Travel Tests', command: () => this.chinaTests() },
      //     // { label: 'Covid or Flu', command: () => this.promotionsNavs('covid-or-flu') },

      //   ]
      // },
      // {
      //   label: 'Diagnostics',
      //   items: [
      //     { label: 'Diagnostics Home', command: () => this.diagnostics() },
      //     // { label: 'Concepto-GBS', command: () => this.menuClick('GBS') },
      //     // { label: 'STI', command: () => this.sti() },
      //     { label: 'Pathology Tests', command: () => this.bloodTests() }

      //   ]
      // },
      // { label: 'Wellness', command: () => this.wellness() },
      // { label: 'Nutrition', command: () => this.nutrition() },
      // { label: 'NIPT', command: () => this.nipt() },

      {
        label: 'More',
        items: [
          // { label: 'Profile', command: () => this.profile() },
          { label: 'Blog', command: () => this.blogs() },
          // { label: 'Ultrasound', command: () => this.imaging() },
          // { label: 'Order Home Test Kits', command: () => this.hometestKits() },
          // { label: 'Activate Postal kit', command: () => this.postalKit() },
          // { label: 'Corporate Testing', command: () => this.corporateTests() },
          { label: 'FAQs', command: () => this.faqs() },
          { label: 'About Us', command: () => this.aboutus() },
          { label: 'Contact Us', command: () => this.contactUs() },
          // { label: 'Logout', command: () => this.logout() },
        ]
      }
    ]

  }

  blogs() {
    this.router.navigateByUrl(`blog`);
    this.visibleSidebar = false;
  }

  bloodTests() {
    this.router.navigateByUrl(`diagnostics/pathology-test-profiles`);
    this.visibleSidebar = false;
  }

  // bluepink(){
  //   this.route
  // }
}
